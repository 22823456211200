import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const canaryManagerPlugin = createPlugin({
  id: 'canary-manager',
  routes: {
    root: rootRouteRef,
  },
});

export const CanaryManagerPage = canaryManagerPlugin.provide(
  createRoutableExtension({
    name: 'CanaryManagerPage',
    component: () =>
      import('./components/CanaryManagerPageComponent').then(m => m.CanaryManagerPageComponent),
    mountPoint: rootRouteRef,
  }),
);
